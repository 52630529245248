import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactForm from '../components/contactForm'

const ContactPage = () => (
  <Layout>
    <SEO title="Contact Us" />
    <section
      css={`
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        width: 100%;
        @media (max-width: 775px) {
          align-items: unset;
          flex-direction: column;
        }
        z-index: 1;
      `}
    >
      <div
        css={`
          margin: 40px auto;
          padding: 0;
          width: 40%;
          @media (max-width: 775px) {
            margin-top: 50px;
            margin-right: 0;
            order: 1;
            text-align: center;
            width: 100%;
          }
        `}
      >
        <h4
          css={`
            text-align: center;
            @media (max-width: 775px) {
              font-size: 0.9rem;
            }
          `}
        >
          Email: info@magnatome.com
        </h4>
      </div>
      {/* <ContactForm /> */}
    </section>
  </Layout>
)

export default ContactPage
